.form-holder {
  .logo-holder {
    img {
      width: 100%;
      max-width: 400px;
    }
  }

  .elements {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    max-width: 330px;
    margin: 0 auto;

    .MuiButton-root{
        margin-top: 40px;
    }

    .MuiTextField-root{
      margin-top: 15px;
    }

  }
}
