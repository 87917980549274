.error-screen {
  background: url("../../images/Johnnie_Walker_Splash.jpg") no-repeat;
  background-size: cover;
  background-position: bottom center;
  min-height: 600px;

  h1 {
    text-align: center;
    font-size: 24px;
    color: #8f5a43;

    @media (min-width: 994px) {
      background: rgba(0, 0, 0, 0.8);
      padding: 20px;
      color: #fff;
    }
  }
}
