.editor {
  margin: 15px;
  background-color: #fff;
  min-height: 600px;

  .rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    min-height: 600px;
    padding: 15px;
    background: #efefef;
  }
}
