@import url('https://fonts.googleapis.com/css2?family=Prata:wght@400;500;600;800&display=swap');

body {
  background-color: #e1e1e1;
}

h1, h2, p, ul, ol, li{
  font-family: 'Prata', serif;
}

.MuiBottomNavigation-root{
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 0 12px rgba(0,0,0,0.3);
  z-index: 5;
}
