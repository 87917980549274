.header{
    display: flex;
    justify-content: space-between;
    padding:15px;
    background-color: #602121;
    margin-bottom: 20px;

    button{
        color:#fff;
        margin-left: 20px;
        font-family: "Prata", serif;
    }

    img{
        max-width: 200px;
    }
}