.footer {
  button {
    span {
      font-family: "Prata", serif;
      color: #000;
    }

    svg {
      color: #000;
    }

    &.Mui-selected {
      svg {
        color: #602121;
      }

      span {
        color: #602121;
      }
    }
  }
}
