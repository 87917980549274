.page-container {
  padding: 15px;
  margin-bottom: 80px;

  @media (min-width: 1000px) {
    max-width: 900px;
    margin: 0 auto 80px;
  }

  .page-content {
    font-family: 'Prata', serif;
    line-height: 1.8em;
    .question {
      font-weight: 800;
      font-size: 16px;
      color: #602121;
    }
    .answer {
      font-size: 14px;
      margin: 4px 0 20px 0;
      color: #333;
    }
  }
}
