.news-list {
  padding: 30px;
  margin-bottom: 50px;

  @media (min-width: 994px) {
    max-width: 1000px;
    margin: 0 auto 50px;
  }

  .news-item {
    display: flex;
    flex-direction: column;
    padding: 25px 0;
    margin-bottom: 30px;
    font-family: "Prata", serif;
    border-bottom: 1px solid #aaa;

    h2 {
      padding: 0;
      margin: 0 0 4px 0;
      color: #602121;
      font-size: 22px;
    }

    .meta {
      font-size: 14px;
      border-left: 8px solid #602121;
      padding-left: 5px;
      margin-bottom: 20px;
    }

    .news-item-desc{
        font-size: 18px;
        line-height: 1.6em;
    }
  }
}
